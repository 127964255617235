// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// MUI
import { Chip, Tooltip, Typography } from '@mui/material';
// redux
import { useAppSelector } from '../../../../../../app/hooks';
// interface
import { Survey } from '../../../../../../interfaces/survey.interface';
import { HardeningDocument } from '../../../../../../interfaces/hardeningDocuments';
import { AssessmentSurvey } from '../../../../../../interfaces/assessment.interface';
import User, {
  Application,
  Version,
  Company,
  CompanyApplication,
} from '../../../../../../interfaces/users.interface';
// service
import { recentBadgeByType, fetchGoldBadgeLabel, fetchBlueBadgeLabel } from '../../../../../../services/badge';
import { fetchAssessmentSurvey } from '../../../../../../services/assessments';
import { fetchSurvey as fetchSurveyService } from '../../../../../../services/surveys';
// components
import GetBadge from '../../../../GetBadge';
import WorkflowStatus from '../../../../WorkflowStatus';
import WorkflowButtons from '../../../../WorkflowButtons';
import { DataCell, DataRow } from '../../../../../Tables/DataList';

type VersionDataRowProps = {
  company: Company;
  companyApp: CompanyApplication;
  application: Application;
  version: Version;
  survey?: Survey;
  assessmentSurvey?: AssessmentSurvey;
  onClick: (companyApp: CompanyApplication, app: Application, version: Version) => void;
};

const VersionDataRow = (props: VersionDataRowProps) => {
  const { application, version, company, companyApp, onClick } = props;
  // redux
  const { documents } = useAppSelector((state) => state.serviceCategories);
  const { applicationVersions: avs } = useAppSelector((state) => state.serviceCategories);
  const { workflows: companyWorkflows } = useAppSelector((state) => state.workflow);
  // states
  const [curSurvey, setCurSurvey] = useState<Survey | undefined>();
  const [assessmentSurvey, setAssessmentSurvey] = useState<AssessmentSurvey | undefined>();
  // local variables
  const workflows = useMemo(
    () =>
      companyWorkflows?.filter(
        (wf) =>
          (wf.application as Application)?.id === application?.id &&
          (wf.version as Version)?.id === version?.id &&
          wf.isActive,
      ),
    [companyWorkflows, application?.id, version?.id],
  );
  const workflow = useMemo(() => (workflows && workflows.length ? workflows[0] : undefined), [workflows]);

  const handleFetchSurvey = useCallback(async (surveyId: number) => {
    const sur = await fetchSurveyService(surveyId);
    setCurSurvey(sur);
  }, []);

  const handleFetchAssessmentSurvey = useCallback(async (assessmentSurveyId: number) => {
    const assSurvey = await fetchAssessmentSurvey(assessmentSurveyId);
    setAssessmentSurvey(assSurvey);
  }, []);

  const handleDocClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, id?: number) => {
      e.stopPropagation();
      if (!id) return;
      const doc = documents.find((cdoc) => cdoc.id === id);
      if (!doc) return;

      const link = document.createElement('a');
      link.setAttribute('href', doc.document);
      link.setAttribute('target', '_blank');
      link.click();
    },
    [documents],
  );

  const docs = useMemo(() => {
    if (!application.hardeningDocuments?.length) return [];
    const curDocs = (application.hardeningDocuments as HardeningDocument[]).filter(
      (hd) => hd.version === version.value,
    );
    if (!curDocs) return [];
    return curDocs;
  }, [application.hardeningDocuments, version.value]);

  const companyCerts = useMemo(() => {
    if (!avs.length || !company.companyCertifications || company.companyCertifications.length === 0) return undefined;
    return company.companyCertifications.filter(
      (cc) =>
        cc.applicationVersions &&
        cc.applicationVersions.some((av) => {
          const CURAVS = avs.find((cav) => cav.id === av);
          if (!CURAVS) return;
          return (CURAVS.version as Version).id === version.id;
        }),
    );
  }, [avs, company?.companyCertifications, version?.id]);

  // loads the survey
  useEffect(() => {
    // if I have an assessment and status is in remediation, load survey
    if (workflow?.assessmentSurvey && workflow?.status !== 'Complete') {
      // fetch assessment survey
      handleFetchAssessmentSurvey(workflow?.assessmentSurvey);
      return;
    }

    // we have a bp survey and no assessment
    if (
      workflow?.bpSurvey &&
      !workflow?.assessment &&
      !['submittedForAssessment', 'complete'].includes(workflow?.status)
    ) {
      // fetch survey
      handleFetchSurvey(workflow?.bpSurvey);
      return;
    }
  }, [workflow]);

  const mostRecentBlue = useMemo(() => workflow && recentBadgeByType([workflow], 'tpn_self_reported'), [workflow]);
  const mostRecentGold = useMemo(() => workflow && recentBadgeByType([workflow], 'tpn_assessed'), [workflows]);

  return (
    <DataRow
      hover
      onClick={() => onClick(companyApp, application, version)}
      sx={{ marginLeft: '-24px', width: 'calc(100% + 24px)', '& .MuiGrid-item': { paddingLeft: '40px' } }}
    >
      <DataCell xs={1} textAlign='center'>
        {version.badge && typeof version.badge !== 'number' && (
          <GetBadge
            isActive={company.isPublished}
            goldBadgeLabel={mostRecentGold?.badge && fetchGoldBadgeLabel([mostRecentGold.badge], mostRecentGold.version)}
            blueBadgeLabel={mostRecentBlue?.badge && fetchBlueBadgeLabel([mostRecentBlue.badge], mostRecentBlue.version)}
            assessmentSurvey={assessmentSurvey}
            assessor={workflow?.assignedAssessor as User | undefined}
            certification={companyCerts?.at(-1)}
            company={company.id}
          />
        )}
      </DataCell>
      <DataCell xs={3} textAlign='center'>
        <Tooltip title={version.value}>
          <Typography sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {version.value}
          </Typography>
        </Tooltip>
      </DataCell>
      <DataCell xs={3} textAlign='center'>
        {docs.map((cd) => (
          <Chip
            key={cd.id}
            label={cd.type}
            onClick={(e) => handleDocClick(e, cd.document)}
            sx={{ bgcolor: '#DFF3D2', color: '#575757' }}
          />
        ))}
      </DataCell>
      <DataCell xs={2} textAlign='center'>
        <WorkflowStatus workflow={workflow} company={company} survey={curSurvey} assessmentSurvey={assessmentSurvey} />
      </DataCell>
      <DataCell xs={3}>
        <WorkflowButtons company={company} workflow={workflow} survey={curSurvey} assessmentSurvey={assessmentSurvey} companyApplication={companyApp} version={version} />
      </DataCell>
    </DataRow>
  );
};

export default VersionDataRow;
