import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState, useMemo } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { BillingFrequency, billingFrequencyOptions } from '../../../interfaces/billing.interface';
import createContact, { updateContact } from '../../../services/contact';
import createCompany, { updateCompany }  from '../../../services/company';
import { Company, Contact, CompanySubmissionData, Regions } from '../../../interfaces/users.interface';
import { useAppSelector } from '../../../app/hooks';
import { StandardDialogActions } from '../../Modals/StandardDialog';
import CompanyPrimaryContactButton from '../../Directory/Profile/CompanyPrimaryContactButton';
import { Autocomplete, Chip, FormControl, InputLabel, Select } from '@mui/material';

const CompanyForm = (props: { displayTypeField: boolean, successCallback: any, submitButtonText: string, assignUserToEmployees: boolean, companyType: string | undefined, assignUserToAdmins: boolean, company: Company | undefined, companyName?: string, primaryContactCallback?: any }) => {
  const {
    displayTypeField,
    successCallback,
    submitButtonText,
    assignUserToEmployees,
    assignUserToAdmins,
    company,
    companyName,
    primaryContactCallback,
  } = props;
  const {
    user,
  } = useAppSelector(state => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors, 
    control,
  } = useForm();
  const [billingContactCheckbox, handleSetBillingContactCheckbox] = useState(
    !company?.billingContact?.name ? true :
      (!company?.primaryContactUser || company?.billingContact?.name === company?.primaryContactUser.firstName + ' ' + company?.primaryContactUser.lastName) &&
    company?.contact?.address === company?.billingContact?.address &&
    company?.contact?.address2 === company?.billingContact?.address2 &&
    company?.primaryContactUser?.email === company?.billingEmailTo &&
    !company?.billingEmailCc &&
    company?.contact?.phoneNumber === company?.billingContact?.phoneNumber &&
    company?.contact?.country === company?.billingContact?.country &&
    company?.contact?.state === company?.billingContact?.state &&
    company?.contact?.city === company?.billingContact?.city &&
    company?.billingStatementName === company?.name ? true : false,
  );
  const [isAssessor, setIsAssessor] = useState(company && company.type === 'assessor');
  const [operatingRegions, setOperatingRegions] = useState(company ? company.operatingRegions : []);
  const isAdmin = useMemo(() => user?.type === 'tpn_admin', [user]);
  const [akaNames, setAkaNames] = useState<string[]>(company?.akaNames ?? []);
  const [fkaNames, setFkaNames] = useState<string[]>(company?.fkaNames ?? []);
  const [dbaNames, setDbaNames] = useState<string[]>(company?.dbaNames ?? []);

  useEffect(() => {
    setValue('region', 'US/CAN');
    if (!company) return;
    setValue('name', company.name);
    setValue('domain', company.domain);
    setValue('region', company.region);
    setValue('grossRevenue', company.grossRevenue);
    setValue('employeeCount', company.employeeCount);
    setValue('billingEmailTo', company.billingEmailTo);
    setValue('billingEmailCc', company.billingEmailCc);
    setValue('billingPoNumber', company.billingPoNumber);
    setValue('billingStatementName', company.billingStatementName);
    setValue('akaNames', company.akaNames);
    setValue('fkaNames', company.fkaNames);
    setValue('dbaNames', company.dbaNames);

    if (company.contact) {
      setValue('phoneNumber', company.contact?.phoneNumber);
      setValue('address', company.contact?.address);
      setValue('address2', company.contact?.address2);
      setValue('address3', company.contact?.address3);
      setValue('zipcode', company.contact?.zipcode);
      setValue('country', company.contact?.country);
      setValue('state', company.contact?.state);
      setValue('city', company.contact?.city);
    }
    if (company.billingContact) {
      setValue('billingName', company.billingContact?.name);
      setValue('billingAddress', company.billingContact?.address);
      setValue('billingAddress2', company.billingContact?.address2);
      setValue('billingAddress3', company.billingContact?.address3);
      setValue('billingPhoneNumber', company.billingContact?.phoneNumber);
      setValue('billingZipcode', company.billingContact?.zipcode);
      setValue('billingCountry', company.billingContact?.country);
      setValue('billingState', company.billingContact?.state);
      setValue('billingCity', company.billingContact?.city);
    }
  }, []);

  useEffect(() => {
    if (!companyName) return;
    setValue('name', companyName);
  }, [companyName]);

  const createBillingContactObject = (data: FieldValues, companyToUpdate?: Company) => {
    let billingContact: Contact = {
      name: billingContactCheckbox ? companyToUpdate?.primaryContactUser ? companyToUpdate?.primaryContactUser.firstName + ' ' + companyToUpdate?.primaryContactUser.lastName : user?.id && assignUserToAdmins ? user.firstName + ' ' + user.lastName : '' : data.billingName,
      address: billingContactCheckbox ? data.address : data.billingAddress,
      address2: billingContactCheckbox ? data.address2 : data.billingAddress2,
      address3: billingContactCheckbox ? data.address3 : data.billingAddress3,
      phoneNumber: billingContactCheckbox ? data.phoneNumber : data.billingPhoneNumber,
      zipcode: billingContactCheckbox ? data.zipcode : data.billingZipcode ? data.billingZipcode : undefined,
      country: billingContactCheckbox ? data.country : data.billingCountry,
      state: billingContactCheckbox ? data.state : data.billingState,
      city: billingContactCheckbox ? data.city : data.billingCity,
    };
    if (company?.billingContact?.id) {
      billingContact.id = company.billingContact.id;
    }
    return billingContact;
  };

  const handleAkaNameChange = (names: string[]) => {
    setAkaNames(names);
  };
  const handleFkaNameChange = (names: string[]) => {
    setFkaNames(names);
  };
  const handleDbaNameChange = (names: string[]) => {
    setDbaNames(names);
  };

  const update = async (companyToUpdate: Company, data: FieldValues ) => {
    if (!companyToUpdate.id) return;

    let companyContact = companyToUpdate.contact;
    if (companyContact?.id) {
      companyContact = await updateContact({
        ...companyToUpdate.contact,
        name: `${data.name} primary contact`,
        primaryContact: companyToUpdate.primaryContactUser ? companyToUpdate.primaryContactUser?.id : undefined,
        phoneNumber: data.phoneNumber,
        address: data.address,
        address2: data.address2,
        address3: data.address3,
        country: data.country,
        state: data.state,
        city: data.city,
        zipcode: data.zipcode,
        isPrimary: true,
      });
    } else {
      companyContact = await createContact({
        ...companyToUpdate.contact,
        name: `${data.name} primary contact`,
        primaryContact: companyToUpdate.primaryContactUser ? companyToUpdate.primaryContactUser?.id : undefined,
        phoneNumber: data.phoneNumber,
        address: data.address,
        address2: data.address2,
        address3: data.address3,
        country: data.country,
        state: data.state,
        city: data.city,
        zipcode: data.zipcode,
        isPrimary: true,
      });
    }

    const updatedCompanyObject: CompanySubmissionData = {
      id: companyToUpdate.id,
      contact: companyContact,
      name: data.name,
      domain: data.domain,
      grossRevenue: data.grossRevenue ? data.grossRevenue : '',
      employeeCount: data.employeeCount ? data.employeeCount : '',
      region: data.region ? data.region : '',
      isPaid: companyToUpdate.isPaid,
      isPublished: companyToUpdate.isPublished,
      billingFrequency: data.billingFrequency,
      billingEmailCc: billingContactCheckbox ? '' : data.billingEmailCc,
      billingEmailTo: billingContactCheckbox ? company?.primaryContactUser ? company?.primaryContactUser.email : '' : data.billingEmailTo,
      billingPoNumber: data.billingPoNumber,
      vatNumber: data.vatNumber,
      billingContact: createBillingContactObject(data, companyToUpdate),
      billingStatementName: billingContactCheckbox ? data.name : data.billingStatementName,
      operatingRegions: operatingRegions,
      akaNames,
      fkaNames,
      dbaNames,
    };
    updateCompany(updatedCompanyObject).then((updatedCompany: Company) => successCallback(updatedCompany));
  };

  const onSubmit = (data: FieldValues) => {
    const newContact: Contact = {
      name: `${data.name} primary contact`,
      phoneNumber: data.phoneNumber,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      isPrimary: true,
    };
    const newCompany: CompanySubmissionData = {
      name: data.name,
      domain: data.domain,
      locations: [],
      services: [],
      employees: (user?.id && assignUserToEmployees) ? [user.id] : [],
      region: data.region,
      isPaid: false,
      isPublished: false,
      grossRevenue: data.grossRevenue,
      employeeCount: data.employeeCount,
      billingFrequency: data.billingFrequency,
      billingPoNumber: data.billingPoNumber,
      billingEmailTo: billingContactCheckbox ? user?.id && assignUserToAdmins ? user.email : '' : data.billingEmailTo,
      billingEmailCc: billingContactCheckbox ? '' : data.billingEmailCc,
      billingStatementName: billingContactCheckbox ? data.name : data.billingStatementName,
      vatNumber: data.vatNumber,
      contact: newContact,
      admins: (user?.id && assignUserToAdmins) ? [user.id] : [],
      type: data.type ? data.type : props.companyType,
      billingContact: createBillingContactObject(data),
      operatingRegions: operatingRegions,
      akaNames,
      fkaNames,
      dbaNames,
    };
    if (company) {
      update(company, data);
      clearErrors();
    } else {
      createCompany(newCompany).then((responseCompany: Company) => {
        clearErrors();
        reset();
        successCallback(responseCompany);
      }).catch((responseError: any) => {
        const errorData = responseError.response?.data;
        if (!errorData) {
          clearErrors();
          return;
        }
        for (const field of Object.keys(errorData)) {
          const errorMsgArr: string[] = errorData[field];
          setError(field, { type: 'manual', message: errorMsgArr[0] });
        }
      });
    }
  };

  return (
    <Grid container>
      <Box sx={{ width: '100%' }} component="form" method="post" autoComplete="do-not-autofill" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='name'
              label='Company Name'
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              autoComplete="do-not-autofill"
              disabled={ user?.type !== 'tpn_admin' && company?.type === 'assessor' }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='domain'
              label='Website Domain'
              {...register('domain')}
              error={!!errors.domain}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='vatNumber'
              label='VAT Number'
              defaultValue={company?.vatNumber}
              {...register('vatNumber')}
              error={!!errors.vatNumber}
              autoComplete="do-not-autofill"
            />
          </Grid>
          { isAdmin && (
           <Grid item xs={12} md={6}>
             <TextField
               select={true}
               required
               fullWidth
               margin='normal'
               id='billingFrequency'
               label='Billing Frequency'
               InputLabelProps={{ shrink: true }}
               defaultValue={company?.billingFrequency ? company.billingFrequency : BillingFrequency.Annually}
               {...register('billingFrequency')}
             >
               {billingFrequencyOptions.map((option) => (
                 <MenuItem key={option.key} value={option.value}>
                   {option.key}
                 </MenuItem>
               ))}
             </TextField>
           </Grid>
          )}
          {!isAssessor && <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingPoNumber'
              label='Billing PO Number'
              {...register('billingPoNumber')}
              error={!!errors.billingPoNumber}
              autoComplete="do-not-autofill"
            />
          </Grid>}
          {!isAssessor && <>
            {!props.company && <Grid item xs={12} md={6}>
              <TextField
                select={true}
                required
                fullWidth
                margin='normal'
                id='grossRevenue'
                label='Gross Revenue'
                InputLabelProps={{ shrink: true }}
                defaultValue={company?.grossRevenue ? company.grossRevenue : ''}
                {...register('grossRevenue')}
              >
                <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                <MenuItem value="Up to $2M">Up to $2M</MenuItem>
                <MenuItem value="$2 - 5M">$2 - 5M</MenuItem>
                <MenuItem value="$5 - 10M">$5 - 10M</MenuItem>
                <MenuItem value="$10 - 15M">$10 - 15M</MenuItem>
                <MenuItem value="$15 - 25M">$15 - 25M</MenuItem>
                <MenuItem value="$25 - 35M">$25 - 35M</MenuItem>
                <MenuItem value="$35 - 50M">$35 - 50M</MenuItem>
                <MenuItem value="$50 - 200M">$50 - 200M</MenuItem>
                <MenuItem value="$200M+">$200M+</MenuItem>
              </TextField>
            </Grid>}
            <Grid item xs={12} md={6}>
              <TextField
                select={true}
                required
                fullWidth
                margin='normal'
                id='employeeCount'
                label='Employee Count'
                InputLabelProps={{ shrink: true }}
                defaultValue={company?.employeeCount ? company.employeeCount : ''}
                {...register('employeeCount')}
              >
                <MenuItem value="1 person with no other employees">1 person with no other employees</MenuItem>
                <MenuItem value="2 to 20 employees">2 to 20 employees</MenuItem>
                <MenuItem value="21 to 50 employees">21 to 50 employees</MenuItem>
                <MenuItem value="51 to 100 employees">51 to 100 employees</MenuItem>
                <MenuItem value="101 to 200 employees">101 to 200 employees</MenuItem>
                <MenuItem value="201 to 300 employees">201 to 300 employees</MenuItem>
                <MenuItem value="301 or more employees">301 or more employees</MenuItem>
              </TextField>
            </Grid>
          </>}
          <Grid item xs={12} md={6}><FormControl fullWidth>
            <InputLabel id="operating-regions-select-label">Regions *</InputLabel>
            <Select
              required
              multiple
              fullWidth
              id='operatingRegions'
              labelId='operating-regions-select-label'
              label='Regions *'
              value={operatingRegions}
              onChange={(e) => setOperatingRegions(typeof e.target.value === 'string' ? [e.target.value] : e.target.value)}
            >
              {Regions.map(region => (
                <MenuItem key={region} value={region}>{region}</MenuItem>
              ))}
            </Select>
          </FormControl></Grid>
          {displayTypeField &&
            <Grid item xs={12} md={6}>
              <TextField
                select={true}
                required
                fullWidth
                margin='normal'
                id='type'
                label='Company Type'
                InputLabelProps={{ shrink: true }}
                defaultValue={company?.type ? company.type : ''}
                {...register('type')}
                onChange={(e) => setIsAssessor(e.target.value === 'assessor' ? true : company && company.type === 'assessor' )}
              >
                <MenuItem value="vendor">Service Provider</MenuItem>
                <MenuItem value="assessor">Assessor</MenuItem>
                <MenuItem value="content_owner">Content Owner</MenuItem>
              </TextField>
            </Grid>
          }
          {primaryContactCallback && company && <Grid item xs={12}><CompanyPrimaryContactButton companyCallback={primaryContactCallback} company={company} /></Grid>}
          <Grid item xs={12}>
            <Typography variant='h5'>Company Aliases</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={1} component='div' variant="caption">Type below and hit <Typography variant='caption' sx={{ display: 'inline-block', padding: '0 .25em', border: '1px solid black', borderRadius: '5px', textTransform: 'uppercase' }}>enter</Typography> to add alias names (AKA/DBA/FKA).</Typography>
          </Grid>
            <Grid item xs={12}>
            <Autocomplete
              multiple
              options={[] as string[]}
              fullWidth
              freeSolo
              value={akaNames}
              renderInput={(params) => (
                <TextField {...params} label="Also Known As" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => {
                  const { onDelete, key, ...restProps } = getTagProps({ index });
                  return <Chip key={key} label={option as string} {...restProps} />;
                })
              }
              onChange={(e, v) => handleAkaNameChange(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={[] as string[]}
              fullWidth
              freeSolo
              value={fkaNames}
              renderInput={(params) => (
                <TextField {...params} label="Formerly Known As" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => {
                  const { onDelete, key, ...restProps } = getTagProps({ index });
                  return <Chip key={key} label={option as string} {...restProps} />;
                })
              }
              onChange={(e, v) => handleFkaNameChange(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={[] as string[]}
              fullWidth
              freeSolo
              value={dbaNames}
              renderInput={(params) => (
                <TextField {...params} label="Doing Business As" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => {
                  const { onDelete, key, ...restProps } = getTagProps({ index });
                  return <Chip key={key} label={option as string} {...restProps} />;
                })
              }
              onChange={(e, v) => handleDbaNameChange(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>Company Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='address'
              label='Address'
              {...register('address')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin='normal'
              id='address2'
              label='Address 2'
              {...register('address2')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              margin='normal'
              id='address3'
              label='Address 3'
              {...register('address3')}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='country'
              label='Country'
              {...register('country')}
              required
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='state'
              label='State'
              {...register('state')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='city'
              label='City'
              {...register('city')}
              required
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin='normal'
              id='zipcode'
              label='Postal Code'
              {...register('zipcode')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              {...register('phoneNumber')}
              render={({ field }) => (<MuiPhoneNumber
                value={field.value}
                required
                fullWidth
                margin='normal'
                id='phoneNumber'
                label='Phone Number'
                InputLabelProps={{ shrink: true }}
                defaultCountry={'us'}
                variant='outlined'
                onChange={field.onChange}
              />)}
              name={'phoneNumber'}
              control={control}
            />
          </Grid>
          {!isAssessor && <><Grid item xs={12}>
            <Typography variant='h5'>Billing Contact</Typography>
            <FormControlLabel control={<Checkbox checked={billingContactCheckbox} onClick={() => handleSetBillingContactCheckbox(!billingContactCheckbox)} />} label="Same as Above" />
          </Grid>
          {!billingContactCheckbox && <>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              margin='normal'
              id='billingStatementName'
              label='Billing Statement Name'
              InputLabelProps={{ shrink: true }}
              {...register('billingStatementName')}
              autoComplete="do-not-autofill"
              required
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              margin='normal'
              id='billingName'
              label='Billing Contact Name'
              InputLabelProps={{ shrink: true }}
              {...register('billingName')}
              autoComplete="do-not-autofill"
              required
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingEmailTo'
              label='Billing Email To'
              {...register('billingEmailTo')}
              error={!!errors.billingEmailTo}
              helperText={errors.billingEmailTo && errors.billingEmailTo.message}
              autoComplete="do-not-autofill"
              required
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingEmailCc'
              label='Billing Email CC'
              {...register('billingEmailCc')}
              error={!!errors.billingEmailCc}
              helperText={errors.billingEmailCc && errors.billingEmailCc.message}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin='normal'
              id='billingAddress'
              label='Billing Address'
              InputLabelProps={{ shrink: true }}
              {...register('billingAddress')}
              autoComplete="do-not-autofill"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin='normal'
              id='billingAddress2'
              label='Billing Address 2'
              InputLabelProps={{ shrink: true }}
              {...register('billingAddress2')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              margin='normal'
              id='billingAddress3'
              label='Billing Address 3'
              InputLabelProps={{ shrink: true }}
              {...register('billingAddress3')}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingCountry'
              label='Country'
              {...register('billingCountry')}
              required
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingState'
              label='State'
              {...register('billingState')}
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingCity'
              label='City'
              {...register('billingCity')}
              required
              autoComplete="do-not-autofill"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin='normal'
              id='billingZipcode'
              label='Postal Code'
              InputLabelProps={{ shrink: true }}
              {...register('billingZipcode')}
              autoComplete="do-not-autofill"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              {...register('billingPhoneNumber')}
              render={({ field }) => (<MuiPhoneNumber
                value={field.value}
                fullWidth
                margin='normal'
                id='billingPhoneNumber'
                label='Phone Number'
                InputLabelProps={{ shrink: true }}
                defaultCountry={'us'}
                variant='outlined'
                onChange={field.onChange}
              />)}
              name={'billingPhoneNumber'}
              control={control}
            />
          </Grid></>}</>}
        </Grid>
        <StandardDialogActions>
          <Button type="submit" variant="contained" endIcon={<NavigateNextIcon />}>{submitButtonText}</Button>
        </StandardDialogActions>
      </Box>
    </Grid>
  );
};

CompanyForm.defaultProps = {
  displayTypeField: false,
  companyType: '',
  assignUserToAdmins: false,
  company: undefined,
};

export default CompanyForm;