import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';
import axios from 'axios';
import getAuthSession from '../../services/auth';
import { setQuestionStatus, setCompanyCertifications, setCertControlsById as importSetCertControlsById } from '../../app/store/questionnaireAnswerSlice';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

import { Question } from '../../interfaces/question.interface';
import { Answer } from '../../interfaces/answer.interface';
import { QuestionTypes } from './QuestionTypes';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { InclusionConfiguration } from '../../interfaces/inclusionConfiguration.interface';
import { CertificationControl } from '../../interfaces/certificationControl.interface';
import { Certification } from '../../interfaces/certification.interface';
import { updateQuestion, fetchQuestion, resetQuestion } from '../../app/store/questionSlice';

import AnswerList from '../Answers/AnswerList';

import { buildQuestionnaireBreadCrumbs } from '../../services/breadCrumbs';
import { BreadCrumbItem } from '../Cards/HeaderDetailCard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionCollapse, AccordionCollapseSummary } from '../Layout/Collapsible';
import NewAnswerForm from '../Answers/NewAnswerForm';

import LogicList from '../Logic/LogicList';
import NewLogicMapping from '../Logic/NewLogicMapping';
import CertMappingList from '../CertMapping/CertMappingList';
import NewCertMapping from '../CertMapping/NewCertMapping';
import Divider from '@mui/material/Divider';
import QuestionOnQuestionnaire from './QuestionOnQuestionnaire/QuestionOnQuestionnaire';
import BestPracticeHeader from '../BestPractices/BestPracticeHeader';
import IncludedInLogicItem from '../Logic/IncludedInLogic';

export interface IncludedInLogic extends Question {
  inclusionIds?: number[];
}

interface QuestionById {
  [id: string]: Question;
}

export interface CertControlById {
  [id: string]: CertificationControl;
}

export const getQuestionOrder = (question:Question) => {
  if (typeof question.bestPractice === 'number') return;

  if (typeof question.bestPractice.questions[0] === 'number') return question.bestPractice.questions.indexOf(question.id) + 1;

  return question.bestPractice.questions.findIndex(q => q.id === question.id) + 1;
};

export default function QuestionDetail(props: { question: Question, changeView: any, bestPractice: BestPractice }) {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      const { title, description, type, includeComment, commentLabel, includeAttachmentForm, attachmentFormLabel, isBestPractice } = props.question;
      return { title, description, type, includeComment, commentLabel, includeAttachmentForm, attachmentFormLabel, isBestPractice };
    }, [props.question]),
  });

  const kvCompare = (obj1:any, obj2:any, key:any) => {
    return obj1[key] !== obj2[key];
  };

  const [question, setQuestion] = useState<Question | undefined>(undefined);
  const { question: questionResult } = useAppSelector(state => state.questions);
  const [breadCrumbs, setBreadCrumbs] = useState<any[] | undefined>(undefined);
  const [answerWarningOpen, setAnswerWarningOpen] = useState(false);
  const [newAnswerOpen, setNewAnswerOpen] = useState(false);
  const [newLogicOpen, setNewLogicOpen] = useState(false);
  const [newMappingOpen, setNewMappingOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | boolean>('answer');
  const [answers, setAnswers] = useState<Answer[] | undefined>(undefined);
  const [inclusions, setInclusions] = useState<InclusionConfiguration[] | undefined>(undefined);
  const [questionsById, setQuestionsById] = useState<QuestionById>({});
  const [certControlsById, setCertControlsById] = useState<CertControlById>({});
  const [certs, setCerts] = useState<CertificationControl[] | undefined>(undefined);
  const [includedInLogic, setIncludedInLogic] = useState<IncludedInLogic[] | undefined>(undefined);
  const [certification, setCertification] = useState<Certification | undefined>(undefined);
  const [tempData, setTempData] = useState<FieldValues | undefined>(undefined);
  const [questionPreview, setQuestionPreview] = useState<Question | undefined>(undefined);

  const resetForm = () => {
    if (!props.question) return;

    const { title, description, type, includeComment, commentLabel, includeAttachmentForm, attachmentFormLabel, isBestPractice } = props.question;
    reset({ title, description, type, includeComment, commentLabel, includeAttachmentForm, attachmentFormLabel, isBestPractice });
  };

  const buildBreadCrumbs = () => {
    if (!question) return;
    if (typeof question.questionnaire === 'number') return;
    if (typeof question.domain === 'number') return;
    if (typeof question.topic === 'number') return;
    if (typeof question.bestPractice === 'number') return;

    const crumbItems = [
      { ...question.questionnaire, label: 'Questionnaire' },
      { ...question.domain, label: 'Domain' },
      { ...question.topic, label: 'Topic' },
      { ...question.bestPractice, label: 'Best Practice', linkOverride: props.changeView },
    ];
    const breadCrumbsList = buildQuestionnaireBreadCrumbs(crumbItems, '/questionnaires/');
    setBreadCrumbs(breadCrumbsList);
  };

  const buildQuestion = (data: FieldValues) => {
    if (!question) return;

    const { inclusions: newInclusions, excludedByCertifications, answers: newAnswers } = question;
    const inclusionsIdsArr = newInclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
    const certificationsIdsArr = excludedByCertifications.map((cert) => typeof cert === 'number' ? cert : cert.id);
    const answerIdsArr = newAnswers.map((answer) => typeof answer === 'number' ? answer : answer.id);

    let newQuestion = {
      ...question,
      title: data.title,
      description: data.description && data.description.length > 0 ? data.description : null,
      type: data.type,
      includeComment: data.includeComment,
      commentLabel: data.commentLabel,
      isBestPractice: data.isBestPractice,
      includeAttachmentForm: data.includeAttachmentForm,
      attachmentFormLabel: data.attachmentFormLabel,
      questionnaire: typeof question.questionnaire === 'number' ? question.questionnaire : question.questionnaire.id,
      domain: typeof question.domain === 'number' ? question.domain : question.domain.id,
      topic: typeof question.topic === 'number' ? question.topic : question.topic.id,
      bestPractice: typeof question.bestPractice === 'number' ? question.bestPractice : question.bestPractice.id,
      inclusions: inclusionsIdsArr,
      excludedByCertifications: certificationsIdsArr,
      answers: answerIdsArr,
    };

    return newQuestion;
  };

  const shouldShowAnswerWarning = (data: FieldValues) => {
    if (!question || (question.answers && question.answers.length === 0) ) return false;

    return (
      question &&
      (
        ( question.type?.includes('text') && !data.type.includes('text') ) ||
        ( question.type?.includes('select') && !data.type.includes('select') )
      )
    );
  };

  let subscription:any = undefined;

  const debounceUpdateQuestion = useRef(debounce((newQuestion:any) => {
    dispatch(updateQuestion({ ...newQuestion }));
  }, 1000)).current;

  useEffect(() => {
    if (!questionPreview && subscription) { subscription.unsubscribe(); subscription = undefined; }
    if (!question || !questionPreview) return;
    subscription = watch((value) => {
      setQuestionPreview({ ...questionPreview, ...value });
      if (shouldShowAnswerWarning(value)) {
        setAnswerWarningOpen(true);
        setTempData(value);
      } else {
        debounceUpdateQuestion(buildQuestion(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [questionPreview]);

  const fetchIncludedInLogic = async () => {
    if (!question) return;
    const authSession = await getAuthSession();

    axios.get(`${baseUrl}/inclusion-configurations/?question=${question.id}&limit=100`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    )
      .then(res => {
        let configs:InclusionConfiguration[] = res.data.results;

        const promises:Array<any> = [];
        configs.forEach((config) => {
          promises.push(axios.get(`${baseUrl}/inclusion-questions/?inclusion_configuration=${config.id}&limit=9999`,
            {
              headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
            },
          ));
        });

        axios
          .all(promises)
          .then(resp => {
            const newQuestions:Question[] = [].concat.apply([], resp.map(q => q.data.questions));
            const questionsNoDups:IncludedInLogic[] = [...new Map(newQuestions.map((item) => [item.id, item])).values()];
            questionsNoDups.forEach(q => {
              q.inclusions.forEach(inc => {
                const incid = typeof(inc) === 'number' ? inc : inc.id;
                const index = configs.findIndex(c => c.id === incid);
                if (index > -1) q.inclusionIds = q.inclusionIds && typeof(q.inclusionIds) === 'object' ? [ ...q.inclusionIds, incid ] : [ incid ];
              });
            });
            setIncludedInLogic(questionsNoDups);
          });
      });
  };

  useEffect(() => {
    if (!questionResult) { dispatch(fetchQuestion(props.question.id)); return; }

    setQuestion(questionResult);
    dispatch(setQuestionStatus(questionResult.id, 'incomplete', questionResult));
  }, [questionResult]);

  const finishSetInclusions = () => {
    if (!question || (question.inclusions && question.inclusions.length > 0 && typeof question.inclusions[0] === 'number')) return;
    let newInclusions:InclusionConfiguration[] = [];

    question.inclusions.forEach(inclusion=>{
      const questionId = typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id;
      newInclusions.push({ ...inclusion, question: questionsById[questionId] });
    });

    setInclusions(newInclusions);
  };

  const handleSetInclusions = async () => {
    if (!question) return;
    const promises:Array<any> = [];
    const authSession = await getAuthSession();

    question.inclusions.forEach(inclusion=>{
      const questionId = typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id;
      if (!questionsById.hasOwnProperty(questionId)) {
        promises.push(axios.get(
          `${baseUrl}/questions/${typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id}/`,
          {
            headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
          },
        ));
      }
    });

    if (promises.length > 0) {
      axios
        .all(promises)
        .then(res => {
          const questions:Question[] = res.map(newquestion => newquestion.data);
          let newQuestions:QuestionById = { ...questionsById };

          questions.forEach(newquestion => {
            newQuestions[newquestion.id] = newquestion;
          });
          setQuestionsById(newQuestions);
        });
    } else {
      finishSetInclusions();
    }
  };

  useEffect(() => {
    if (questionsById) finishSetInclusions();
  }, [questionsById]);

  const finishSetCerts = () => {
    if (!question || !certControlsById) return;
    let newCerts:CertificationControl[] = [];

    question.excludedByCertifications.forEach(newCert=>{
      const certId = typeof newCert === 'number' ? newCert : newCert.id;
      newCerts.push(certControlsById[certId]);
    });

    setCerts(newCerts);
    setQuestionPreview({ ...question, excludedByCertifications: newCerts });
  };

  const getMappings = async () => {
    if (!question) return;
    const authSession = await getAuthSession();
    const promises:Array<any> = [];

    question.excludedByCertifications.forEach(cert => {
      const certId = typeof cert === 'number' ? cert : cert.id;
      if (!certControlsById.hasOwnProperty(certId)) {
        promises.push(axios.get(
          `${baseUrl}/certification-controls/${certId}/`,
          {
            headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
          },
        ));
      }
    });

    if (promises.length > 0) {
      axios
        .all(promises)
        .then(res => {
          const newCerts:CertificationControl[] = res.map(cert => cert.data);
          let certList:CertControlById = { ...certControlsById };

          newCerts.forEach(newCert => {
            certList[newCert.id] = newCert;
          });
          setCertControlsById(certList);

        });
    } else {
      finishSetCerts();
    }
  };

  useEffect(() => {
    if (certControlsById) {
      finishSetCerts();
      dispatch(importSetCertControlsById(certControlsById));
    }
  }, [certControlsById]);

  useEffect(() => {
    if (!question) return;

    buildBreadCrumbs();
    let newQuestions = { ...questionsById };
    newQuestions[question.id] = question;
    setQuestionsById(newQuestions);

    setAnswers(question.answers);
    if (question.inclusions && question.inclusions.length > 0 && typeof question.inclusions[0] !== 'number') handleSetInclusions();
    getMappings();
    fetchIncludedInLogic();
    if (!question.answers || question.answers.length === 0 || (question.answers.length > 0 && typeof question.answers[0] !== 'number')) setQuestionPreview(question);
    setTempData(undefined);
  }, [question]);

  useEffect(() => {
    if (question && question.id === props.question.id) return;

    setQuestion(undefined);
    setBreadCrumbs(undefined);
    setAnswerWarningOpen(false);
    setNewAnswerOpen(false);
    setNewLogicOpen(false);
    setNewMappingOpen(false);
    setExpanded('answer');
    setAnswers(undefined);
    setInclusions(undefined);
    setQuestionsById({});
    setCertControlsById({});
    setCerts(undefined);
    setIncludedInLogic(undefined);
    setCertification(undefined);
    setTempData(undefined);
    setQuestionPreview(undefined);
    resetForm();

    dispatch(resetQuestion());
  }, [props.question]);

  const updateQuestionRemoveAnswers = () => {
    if (!question || !tempData) return;

    let newQuestion = buildQuestion(tempData);
    dispatch(updateQuestion({ ...newQuestion, answers: [] }));
    setAnswerWarningOpen(false);
  };

  const onSubmit = (data: FieldValues) => {
    if (!question) return;

    if (
      kvCompare(question, data, 'title') ||
      kvCompare(question, data, 'description') ||
      kvCompare(question, data, 'type') ||
      kvCompare(question, data, 'includeComment') ||
      kvCompare(question, data, 'commentLabel') ||
      kvCompare(question, data, 'includeAttachmentForm') ||
      kvCompare(question, data, 'attachmentFormLabel')
    ) {
      if (shouldShowAnswerWarning(data)) {
        setAnswerWarningOpen(true);
        setTempData(data);
      } else {
        let newQuestion = buildQuestion(data);
        dispatch(updateQuestion({ ...newQuestion }));
      }
    }
  };

  const handlePanelChange = (panel: string) => {
    setExpanded(panel ? panel : false);
  };

  const questionUpdate = (updateItems: {
    answers?:Answer[],
    inclusions?:InclusionConfiguration[],
    excludedByCertifications?:CertificationControl[],
  }) => {
    if (!question) return;
    const newQuestion = buildQuestion(question);

    if (!updateItems) {
      dispatch(updateQuestion({ ...newQuestion }));
      return;
    }

    if (updateItems.answers) {
      const answerIdsArr = updateItems.answers.map((answer) => typeof answer === 'number' ? answer : answer.id);
      setAnswers(updateItems.answers);
      dispatch(updateQuestion({ ...newQuestion, answers: answerIdsArr }));
    }
    if (updateItems.inclusions) {
      const inclusionsIdsArr = updateItems.inclusions.map((inclusion) => typeof inclusion === 'number' ? inclusion : inclusion.id);
      dispatch(updateQuestion({ ...newQuestion, inclusions: inclusionsIdsArr }));
    }
    if (updateItems.excludedByCertifications) {
      const certificationsIdsArr = updateItems.excludedByCertifications.map((cert) => typeof cert === 'number' ? cert : cert.id);
      dispatch(updateQuestion({ ...newQuestion, excludedByCertifications: certificationsIdsArr }));
    }
  };

  const toggleCertification = () => {
    if (!question) return;
    if (!certification) setCertification(certs && certs.length > 0 ? certs[0].certification : undefined );
    if (certification) setCertification(undefined);
  };

  useEffect(() => {
    if (certification && (!certs || certs.length === 0)) setCertification(undefined);
    if (certification && certs && certs.length > 0) setCertification(certs[0].certification);
  }, [certs]);

  useEffect(() => {
    const companyCert = {
      company: 1,
      issueDate: new Date(),
      expirationDate: new Date(),
      document: 'thing',
      sites: [],
      applications: [],
      createdAt: new Date(),
    };
    dispatch(setCompanyCertifications(certification ? [{ ...companyCert, certification }] : []));
  }, [certification]);

  const filterOutLogicItem = (id:number) => {
    const newIncIn = includedInLogic?.filter(inc => inc.id !== id);
    setIncludedInLogic(newIncIn);
  };

  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      { question &&
       <Box mt={2} mb={4}>
         { breadCrumbs && breadCrumbs.length > 0 &&
          <Breadcrumbs
             separator={<NavigateNextIcon fontSize="small" />}
             aria-label="breadcrumb"
           >
             {breadCrumbs && breadCrumbs.map((breadCrumb:any) => (
               <BreadCrumbItem
                 key={breadCrumb.urlString}
                 label={breadCrumb.label}
                 slug={breadCrumb.slug}
                 urlString={breadCrumb.urlString}
                 title={breadCrumb.title}
                 linkOverride={breadCrumb.linkOverride}
               />
             ))}
            </Breadcrumbs>
          }
          <Typography variant='overline'>Question</Typography>
          <Box sx={{ width: '100%', mt:1, mb:2, position: 'relative' }} component="form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4, position: 'absolute', bottom: '100%', right: 0 }}>
            <Button variant="outlined" sx={{ ml: 2 }} onClick={() => props.changeView()}>Close</Button>
            </Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  error={!!errors.title}
                  helperText={!!errors.title && errors.title.message}
                  required
                  id='title'
                  label='Question Title'
                  multiline
                  disabled={canEdit === false}
                  fullWidth
                  autoFocus
                  {...register('title')}
                  InputProps={{ style: { fontSize: '2rem' } }}
                  InputLabelProps={{ style: { fontSize: '2rem', transform: 'translate(14px, -16px) scale(0.75)' } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='description'
                  label='Description'
                  disabled={canEdit === false}
                  multiline
                  rows={5}
                  fullWidth
                  {...register('description')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel id="type-label">Type</FormLabel>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-labelledby="type-label"
                        id="type"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                      >
                        {QuestionTypes.map((qType: any) => (
                          <FormControlLabel disabled={canEdit === false} {...register('type')} name="type" key={qType.value} value={qType.value} control={<Radio />} label={qType.label} />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <FormControl sx={{ ml: 2 }}>
                  <FormLabel id="isBestPractice-label">Is Best Practice Question:</FormLabel>
                  <Controller
                    control={control}
                    name="isBestPractice"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-labelledby="isBestPractice-label"
                        id="bestPracticeQuestion"
                        value={value && (typeof(value) !== 'string' || value === 'True') ? 'True' : 'False'}
                        onChange={(e) => onChange(e.target.value)}
                      >
                        <FormControlLabel disabled={canEdit === false} name="isBestPractice" value="True" control={<Radio />} label="True" />
                        <FormControlLabel disabled={canEdit === false} name="isBestPractice" value="False" control={<Radio />} label="False" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" sx={{ display: 'block' }}>
                  <FormGroup aria-label="Include Comment" row>
                    <Controller
                      control={control}
                      name="includeComment"
                      render={({ field: { value } }) => (
                        <FormControlLabel
                          control={<Checkbox disabled={canEdit === false} checked={value} onChange={(e) => {setValue('includeComment', e.target.checked);}} />}
                          label="Show Comment Box"
                        />
                      )}
                    />
                    <TextField
                      error={!!errors.commentLabel}
                      helperText={!!errors.commentLabel && errors.commentLabel.message}
                      id='commentLabel'
                      label='Comment Label'
                      sx={{ width: '100%' }}
                      disabled={canEdit === false}
                      {...register('commentLabel')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" sx={{ display: 'block' }}>
                  <FormGroup aria-label="Include Attachment Form" row>
                    <Controller
                      control={control}
                      name="includeAttachmentForm"
                      render={({ field: { value } }) => (
                        <FormControlLabel
                          control={<Checkbox disabled={canEdit === false} checked={value} onChange={(e) => {setValue('includeAttachmentForm', e.target.checked);}} />}
                          label="Show Attachment Box"
                        />
                      )}
                    />
                    <TextField
                      error={!!errors.attachmentFormLabel}
                      helperText={!!errors.attachmentFormLabel && errors.attachmentFormLabel.message}
                      id='attachmentFormLabel'
                      label='Attachment Form Label'
                      sx={{ width: '100%' }}
                      {...register('attachmentFormLabel')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <StandardDialog title={`Remove answers from ${question?.title}`} handleClose={() => { setValue('type', question.type); setAnswerWarningOpen(false); }} isOpen={answerWarningOpen}>
              <Typography>Changing the question type will delete answers currently associated with this question and any answer responses will be lost. Are you sure  you wish to continue?</Typography>
              <StandardDialogActions>
                <Button variant="outlined" onClick={() => { setValue('type', question.type); setAnswerWarningOpen(false); }}>Cancel</Button>
                <Button variant="contained" color="error" onClick={() => {updateQuestionRemoveAnswers();}}>Delete Answers</Button>
              </StandardDialogActions>
            </StandardDialog>
          </Box>
          { question.type !== 'text_field' && question.type !== 'text' &&
            <AccordionCollapse expanded={expanded === 'answer'} onChange={() => {handlePanelChange('answer');}}>
              <AccordionCollapseSummary name='answers-header' title={`Answers (${answers ? answers.length : 0})`} addItem={canEdit ? () => { setNewAnswerOpen(true); } : null} addItemLabel="Answer">
                <StandardDialog title='Add New Answer' handleClose={() => setNewAnswerOpen(false)} isOpen={newAnswerOpen}>
                  <NewAnswerForm
                    handleClose={() => setNewAnswerOpen(false)}
                    updateQuestion={questionUpdate}
                    answers={answers!}
                    questionId={question.id}
                  />
                </StandardDialog>
              </AccordionCollapseSummary>
              <AccordionDetails>
                {answers && answers.length > 0 &&
                  <AnswerList
                    answers={answers}
                    canEdit={canEdit}
                    questionUpdate={questionUpdate}
                    question={question}
                  />
                }
              </AccordionDetails>
            </AccordionCollapse>
          }
          <AccordionCollapse expanded={expanded === 'logic'} onChange={() => {handlePanelChange('logic');}}>
            <AccordionCollapseSummary name='parent-logic-header' title={`Parent Logic (${inclusions ? inclusions.length : 0})`} addItem={canEdit ? () => { setNewLogicOpen(true); } : null} addItemLabel="Logic">
              <StandardDialog title='Add New Logic' handleClose={() => setNewLogicOpen(false)} isOpen={newLogicOpen}>
                <NewLogicMapping
                  canEdit={canEdit}
                  handleClose={() => setNewLogicOpen(false)}
                  updateQuestion={questionUpdate}
                  inclusionList={inclusions!}
                />
              </StandardDialog>
            </AccordionCollapseSummary>
            <AccordionDetails>
              {inclusions && inclusions.length > 0 &&
                <LogicList
                  canEdit={canEdit}
                  inclusions={inclusions}
                  question={question}
                  questionUpdate={questionUpdate}
                />
              }
            </AccordionDetails>
          </AccordionCollapse>
          <AccordionCollapse expanded={expanded === 'cert'} onChange={() => {handlePanelChange('cert');}}>
            <AccordionCollapseSummary name='mapping-header' title={`Mapping (${certs ? certs.length : 0})`} addItem={canEdit ? () => { setNewMappingOpen(true); } : null} addItemLabel="Mapping">
              <StandardDialog title='Add New Mapping' handleClose={() => setNewMappingOpen(false)} isOpen={newMappingOpen}>
                <NewCertMapping 
                  questionUpdate={questionUpdate}
                  certList={certs}
                  handleClose={() => setNewMappingOpen(false)}
                />
              </StandardDialog>
            </AccordionCollapseSummary>
            <AccordionDetails>
              {certs && certs !== undefined && certs.length > 0 &&
                <CertMappingList
                  canEdit={canEdit}
                  certList={certs}
                  questionUpdate={questionUpdate}
                  question={question}
                  answers={answers}
                />
              }
            </AccordionDetails>
          </AccordionCollapse>
          <AccordionCollapse expanded={expanded === 'includedIn'} onChange={() => {handlePanelChange('includedIn');}}>
            <AccordionCollapseSummary name='child-logic-header' title={`Child Logic (${includedInLogic ? includedInLogic.length : '0'})`} />
            <AccordionDetails>
              { includedInLogic && includedInLogic.length > 0 &&
                <Stack spacing={2}>
                  { includedInLogic.map((includedInLogicItem) => (
                    <IncludedInLogicItem canEdit={canEdit} key={includedInLogicItem.id} includedInLogic={includedInLogicItem} callback={(id:number) => { filterOutLogicItem(id); }} />
                  ))}
                </Stack>
              }
            </AccordionDetails>
          </AccordionCollapse>
          <Divider sx={{ margin: '2em 0' }}>Question Preview { certs && certs.length > 0 && <Button size="small" variant="outlined" onClick={() => toggleCertification()}>Toggle Certificate</Button> }</Divider>
          <Box mb={1}><BestPracticeHeader bestPractice={props.bestPractice} /></Box>
          {questionPreview &&
            <QuestionOnQuestionnaire
              question={questionPreview}
            />
          }
        </Box>
      }
    </>
  );
}