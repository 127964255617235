import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { OnboardingContainer } from './OnboardingForm';
import CompanyForm from '../../Companies/Forms/CompanyForm';
import User, { Company } from '../../../interfaces/users.interface';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { QuestionnaireResultArray } from '../../../interfaces/questionnaire.interface';
import { getOnBoardingQuestionnaires } from '../../../services/questionnaires';
import createSurvey from '../../../services/surveys';
import { Survey } from '../../../interfaces/survey.interface';
import { updateUser, updateUserCompanies } from '../../../app/store/userSlice';
import { Autocomplete, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import getAuthSession from '../../../services/auth';
import axios, { AxiosResponse } from 'axios';
import throttle from 'lodash/throttle';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { setPendingUser } from '../../../services/user';
import sendPendingEmployeeNotice from '../../../services/notifications';
import { BillingFrequency } from '../../../interfaces/billing.interface';

const CompanyOnBoardingForm = () => {
  let { user } = useAppSelector(state => state.user);
  const [companyType, setCompanyType] = useState('');
  const [searchedCompany, setSearchedCompany] = useState<Company | undefined>(undefined);
  const [itemList, setItemList] = useState<Company[]>([]);
  const [JWToken, setJWToken] = useState('');
  const companySearchRef = useRef(null);
  const [view, setView] = useState('searchCompany');

  const [createCompanyOpen, setCreateCompanyOpen] = useState(false);
  const handleCreateCompanyOpen = () => setCreateCompanyOpen(true);
  const handleCreateCompanyClose = () => {
    if (companySearchRef && companySearchRef.current) {
      const autoCRef = (companySearchRef.current as HTMLElement).getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
      if (autoCRef) autoCRef.click();
    }
    setCreateCompanyOpen(false);
  };

  const [joinCompanyOpen, setJoinCompanyOpen] = useState(false);
  const handleJoinCompanyOpen = () => setJoinCompanyOpen(true);
  const handleJoinCompanyClose = () => {
    if (companySearchRef && companySearchRef.current) {
      const autoCRef = (companySearchRef.current as HTMLElement).getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
      if (autoCRef) autoCRef.click();
    }
    setJoinCompanyOpen(false);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAuthSession().then((session) => {
      setJWToken(session.getIdToken().getJwtToken());
    });
  }, []);

  const mapQuestionnaireType = () => {
    if (!companyType) return '';
    if (companyType == 'content_owner') return 'content_owner_onboarding';
    if (companyType == 'assessor') return 'assessor_onboarding';
    if (companyType == 'vendor') return 'vendor_onboaring';
    return '';
  };

  const createOnBoardingSurvey = (company: Company) => {
    if (!company.id) return navigate('/profile');
    const questionnaireType: string = mapQuestionnaireType();
    getOnBoardingQuestionnaires(questionnaireType).then((questionnaireResponse: QuestionnaireResultArray) => {
      if (questionnaireResponse.results.length < 1) navigate('/profile');
      if (company.id) {
        const surveyData: Survey = {
          questionnaire: questionnaireResponse.results[0].id,
          status: 'incomplete',
          company: company.id,
          questionAnswers: [],
        };
        createSurvey(surveyData).then((surveyResponse: Survey) => navigate(`/questionnaire/${surveyResponse.id}`));
      } else {
        navigate('/profile');
      }
    }).catch(() => navigate('/profile'));
  };

  const updateUserCompaniesAndFetchOnBoardingSurvey = (company: Company) => {
    dispatch(updateUserCompanies(company));
    createOnBoardingSurvey(company);
  };

  const getCompanyTypeFromUser = () => {
    if (!user) return '';
    if (user.type == 'content_owner') return 'content_owner';
    if (user.type == 'vendor_admin') return 'vendor';
    if (user.type == 'assessor') return 'assessor';
    return '';
  };

  useEffect(() => setCompanyType(getCompanyTypeFromUser()), [user]);

  const fetchItems =  (searchText: string) => {
    if (!searchText || !JWToken) return;
    try {
      axios.get(
        `${process.env.REACT_APP_BASE_API}/companies/list/?type=${companyType}&search=${searchText}&isPublished=true`,
        {
          headers: { 'Authorization': `Bearer ${JWToken}` },
        },
      ).then((response: AxiosResponse) => {
        const filteredResults = response.data.results.filter((company: Company) => !company?.isHidden);
        setItemList(filteredResults);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const throttledFetchItems = throttle(fetchItems, 1000, { leading: true, trailing: true });

  const handleInputChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    await throttledFetchItems(name);
  };

  useEffect(() => {
    if (!searchedCompany) return;
    handleJoinCompanyOpen();
  }, [searchedCompany]);

  const setPendingCompany = () => {
    if (!searchedCompany || !user) return;

    setPendingUser(user.id, searchedCompany.id!).then((res) => {
      dispatch(updateUser(res[0].data as User));
      if (searchedCompany.id) sendPendingEmployeeNotice(searchedCompany.id).then(() => {});
      navigate('/waiting-for-approval');
    });
  };

  const handleSelectedCompany = (company: Company) => {
    if (company.id === -1) {
      handleCreateCompanyOpen();
      setSearchedCompany(undefined);
      return;
    }
    setSearchedCompany(company);
  };

  return (
    <OnboardingContainer>
      {/* <Typography variant='h6' mb={2} align='center'>No parent company found. Let&apos;s set one up!</Typography>
      <Typography variant='body1' align='center' mb={2}>
        <Link to='/registry'><Button variant='outlined'>I&apos;ll do this later</Button></Link>
      </Typography> */}
      {view === 'searchCompany' && <>
        <Typography variant='h6'>Search Companies</Typography>
        <Autocomplete
          id='company-serach'
          freeSolo
          fullWidth
          options={itemList}
          ref={companySearchRef}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
          isOptionEqualToValue={(option, value) => option.id == value.id}
          onChange={(e, i) => handleSelectedCompany(i as Company)}
          filterOptions={(options) => {
            // Suggest the creation of a new value
            const filtered = [ ...options, { id: -1,
              name: '+ Add New Company',
              locations: [],
              services: [],
              employees: [],
              pendingEmployees: [],
              billingFrequency: BillingFrequency.Annually,
              region: '',
              isPaid: true,
              isPublished: true,
              grossRevenue: '',
              employeeCount: '',
              admins: [],
              subsidiaries: [],
            } ];
            return filtered;
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={handleInputChange}
              placeholder='Search Companies'
              InputProps={{
                ...params.InputProps,
                endAdornment: (<>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>),
              }}
            />
          }
        />
      </>}
      {view === 'createNewCompany' && <>
        <Typography variant='h6'>Create a new Company</Typography>
        <CompanyForm successCallback={updateUserCompaniesAndFetchOnBoardingSurvey} submitButtonText='Continue' assignUserToEmployees={true} assignUserToAdmins={true} companyType={companyType} />
      </>}

      <StandardDialog title={`Join ${searchedCompany && typeof searchedCompany !== 'string' ? searchedCompany.name : 'this company'}`} handleClose={() => handleJoinCompanyClose()} isOpen={joinCompanyOpen}>
        <Typography>By clicking join, a request will be sent to an admin of {searchedCompany && typeof searchedCompany !== 'string' ? searchedCompany.name : 'this company'} to allow you to join. Are you sure you want to do this?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleJoinCompanyClose()}>Cancel</Button>
          <Button variant="contained" onClick={() => setPendingCompany()}>Join</Button>
        </StandardDialogActions>
      </StandardDialog>

      <StandardDialog title={'Create a new Company'} handleClose={() => handleCreateCompanyClose()} isOpen={createCompanyOpen}>
        <Typography>Are you sure you want create a new company?</Typography>
        <StandardDialogActions>
          <Button variant="outlined" onClick={() => handleCreateCompanyClose()}>Cancel</Button>
          <Button variant="contained" onClick={() => { setView('createNewCompany'); handleCreateCompanyClose(); }}>Create</Button>
        </StandardDialogActions>
      </StandardDialog>
    </OnboardingContainer>
  );
};

export default CompanyOnBoardingForm;