import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useForm, FieldValues } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { createQuestionnaire, updateQuestionnaire } from '../../app/store/questionnaireSlice';
import { StandardDialogActions } from '../Modals/StandardDialog';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { questionnaireTypes } from '../../services/questionnaires';

export default function QuestionnaireForm(props: { handleClose: any, refresh: any }) {
  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm();
  const { error } = useAppSelector(state => state.questionnaires);
  const dispatch = useAppDispatch();
  const onSubmit = (data: FieldValues) => {
    dispatch(createQuestionnaire(data));
  };
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ title: '', detail: '', isPublished: undefined, type: undefined });
      props.refresh();
      props.handleClose();
    }
  }, [formState, reset]);

  return (
    <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ width: '100%' }} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="title"
            label="Questionnaire Title"
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            error={!!error?.title}
            helperText={!!error?.title && error.title[0]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            label="Description"
            multiline
            rows={3}
            sx={{ width: '100%' }}
            {...register('description')}
            error={!!error?.description}
            helperText={!!error?.description && error.description[0]}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='select-type-label'>Type</InputLabel>
            <Select
              labelId='select-type-label'
              id='select-type'
              label='Type'
              {...register('type')}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {questionnaireTypes.map(type => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} pl={2}>
          <FormControlLabel
            id='isPublished'
            control={<Checkbox {...register('isPublished')} name='isPublished'/>}
            label="Publish"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="bestPracticeGuidanceLink"
            label="IG and BP Guidance document"
            sx={{ width: '100%' }}
            autoFocus
            {...register('bestPracticeGuidanceLink')}
            error={!!error?.bestPracticeGuidanceLink}
            helperText={!!error?.bestPracticeGuidanceLink && error.bestPracticeGuidanceLink[0]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}

export function QuestionnaireEditForm(props: { questionnaire: Questionnaire, handleClose: any, refresh?: any }) {
  let [questionnaire] = useState(props.questionnaire);
  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      title: questionnaire.title,
      description: questionnaire.description,
      isPublished: questionnaire.isPublished,
      type: questionnaire.type,
      bestPracticeGuidanceLink: questionnaire.bestPracticeGuidanceLink,
    },
  });
  const dispatch = useAppDispatch();
  const onSubmit = async (data: FieldValues) => {
    data.id = questionnaire.id;
    const { domains } = questionnaire;
    const domainsIdsArr = domains.map((domain) => typeof domain === 'number' ? domain : domain.id);
    const newQuestionnaire: Questionnaire = { ...questionnaire, ...data, domains: domainsIdsArr };
    dispatch(updateQuestionnaire(newQuestionnaire));
  };
  const { error } = useAppSelector(state => state.questionnaires);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.handleClose();
      if (props.refresh) props.refresh();
    }
  }, [formState]);

  return (
    <Box component='form' sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ width: '100%' }} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="title"
            label="Questionnaire Title"
            sx={{ width: '100%' }}
            autoFocus
            {...register('title')}
            error={!!error?.title}
            helperText={!!error?.title && error.title[0]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            label="Description"
            multiline
            rows={3}
            sx={{ width: '100%' }}
            {...register('description')}
            error={!!error?.description}
            helperText={!!error?.description && error.description[0]}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id='select-type-label'>Type</InputLabel>
            <Select
              labelId='select-type-label'
              id='select-type'
              label='Type'
              {...register('type')}
              defaultValue={questionnaire.type ? questionnaire.type : null}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {questionnaireTypes.map(type => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} pl={2}>
          <FormControlLabel
            id={`isPublished-${questionnaire.id}`}
            control={<Checkbox defaultChecked={questionnaire.isPublished} {...register('isPublished')} name='isPublished'/>}
            label="Publish"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="bestPracticeGuidanceLink"
            label="IG and BP Guidance document"
            sx={{ width: '100%' }}
            autoFocus
            {...register('bestPracticeGuidanceLink')}
            error={!!error?.bestPracticeGuidanceLink}
            helperText={!!error?.bestPracticeGuidanceLink && error.bestPracticeGuidanceLink[0]}
          />
        </Grid>
      </Grid>
      <StandardDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </StandardDialogActions>
    </Box>
  );
}