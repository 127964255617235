import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Contact } from '../../../../interfaces/users.interface';
import { InfoOutlined } from '@mui/icons-material';

type SiteNameProps = {
  contact: Contact;
};

const styles = {
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '1.2em',
  },
  icon: {
    mr: 0.5,
    transform: 'translateY(3px)',
  },
};

const SiteName = ({ contact }: SiteNameProps) => {
  const primaryContactDisplay = contact.primaryContact
    ? `${contact.primaryContact.firstName} ${contact.primaryContact.lastName}`
    : '-';
  const primaryContactPhone = contact.primaryContact
    ? contact.primaryContact.contact?.phoneNumber
    : '-';
  const primaryContactEmail = contact.primaryContact
    ? contact.primaryContact.email
    : '-';

  const primaryContactAddress = <>
    {contact.address && <>
      {contact.address}
      {contact.address2 && <><br />{contact.address2}</>}
      <br />{contact.city}, {contact.state} {contact.country} {contact.zipcode} 
    </>}
  </>;

  let tooltipTitle = <>
    {contact.name}<br />
    {contact.primaryContact && <>{primaryContactDisplay}<br /></>}
    {contact.primaryContact && primaryContactPhone && <>{primaryContactPhone}<br /></>}
    {contact.primaryContact && primaryContactEmail}
    <br />
    {contact.primaryContact && primaryContactAddress && <Box>{primaryContactAddress}</Box>}
  </>;

  return (
    <Tooltip
      title={tooltipTitle}
    >
      <Typography
        sx={styles.text}
      >
        <InfoOutlined
          fontSize='small'
          sx={styles.icon}
        />
        {contact.name}
      </Typography>
    </Tooltip>
  );
};

export default SiteName;
