import moment from 'moment';
import { Badge } from '../interfaces/users.interface';
import getAuthSession from './auth';
import { Workflow } from '../interfaces/workflow.interface';
import { workflowLabels } from './workflow';
import axios from 'axios';

export const selectBadgeFromArray = (badges?:Badge[], allowExpired = false) => {
  if (!badges || badges.length === 0) return undefined;
  const today = moment().unix();
  const filteredBadges = badges.filter(badge => badge && (!allowExpired ? moment(badge.expirationDate).unix() > today : true)).sort((a, b) => moment(b.expirationDate).unix() - moment(a.expirationDate).unix());
  if (filteredBadges.find(b => b.title === 'tpn_assessed')) return filteredBadges.filter(badge => badge.title === 'tpn_assessed')[0];
  if (filteredBadges.find(b => b.title === 'tpn_self_reported')) return filteredBadges.filter(badge => badge.title === 'tpn_self_reported')[0];
  return filteredBadges[0];
};

// fetches most recent badge of a specific type from a list of workflows
export const recentBadgeByType = (list: Workflow[], type: string) => {
  return list.reduce((acc, wf) => {
    const candidates = (wf.badges as (Badge | number)[])?.filter((b): b is Badge => typeof b !== 'number' && b.title === type);
    if (!candidates || candidates.length === 0) return acc;
    candidates.sort((a, b) => moment(b.awardedDate).unix() - moment(a.awardedDate).unix());
    if (!acc.badgeDate || moment(acc.badgeDate).unix() < moment(candidates[0].awardedDate).unix()) {
      acc.badgeDate = candidates[0].awardedDate;
      acc.badge = candidates[0];
      acc.version = workflowLabels(wf.workflowType);
    }
    return acc;
  }, {
    badgeDate: '',
    badge: undefined as Badge | undefined,
    version: '',
  });
};

const fetchBadgeTitle = (badges?: Badge[], badgeTitle?: string, targetTitle?: string, color?: string) => {
  if (!badges || badges.length === 0) return undefined;
  const badge = badges.find(b => b.title === targetTitle);
  const today = moment().unix();
  if (!badge) return undefined;
  const exp = moment(badge.expirationDate).unix() > today ? 'Expires ' : 'Expired ';
  const version = badgeTitle ? badgeTitle.split(' ')[1] : '';
  return `${color} - ${version} ${exp}${moment(badge.expirationDate).format('MMM D, YYYY')}`;
};

export const fetchGoldBadgeLabel = (badges?: Badge[], badgeTitle?: string) => {
  return fetchBadgeTitle(badges, badgeTitle, 'tpn_assessed', 'Gold');
};

export const fetchBlueBadgeLabel = (badges?: Badge[], badgeTitle?: any) => {
  return fetchBadgeTitle(badges, badgeTitle, 'tpn_self_reported', 'Blue');
};

export const deleteBadge = async (badgeId: number) => {
  const authSession = await getAuthSession();
  await axios.delete(
    `${process.env.REACT_APP_BASE_API}/badges/${badgeId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return true;
};
