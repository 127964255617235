import { Domain } from './domain.interface';

export interface Questionnaire {
  id: number;
  title: string;
  description: string;
  version: string;
  slug: string;
  domains: Domain[];
  errors?: any;
  isPublished?: boolean;
  type?: string;
  versions?: Questionnaire[];
  bestPracticeGuidanceLink?: string;
}

export interface QuestionnaireResultArray {
  count: number;
  next: string;
  previous: string;
  results: Questionnaire[];
}

export enum ReportVersion {
  v5_1 = 'v5.1',
  v5_2 = 'v5.2',
}