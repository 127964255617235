import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import Moment from 'moment/moment';
import { AccordionDetails, Alert, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Delete, LinkOutlined } from '@mui/icons-material';

import { useAppSelector } from '../../../../app/hooks';
import { Survey } from '../../../../interfaces/survey.interface';
import { AssessmentSurvey } from '../../../../interfaces/assessment.interface';
import {
  Application,
  Company,
  CompanyApplication,
  CompanyDocument,
  CompanyDocumentSubmitData,
  CompanyDocumentUpdateData,
  Contact,
  Version,
} from '../../../../interfaces/users.interface';
import createCompanyDocument, {
  deleteCompanyDocument,
  updateCompanyDocument,
} from '../../../../services/companyDocuments';

import FileUploaderComp from '../../../Forms/FileUpload';
import ChipsWithOverflow from '../../../Layout/ChipsWithOverflow';
import MultipleSelectChip from '../../../Vendors/Forms/MultiSelect';
import { DataCell, DataRow, DataRowActions } from '../../../Tables/DataList';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import { AccordionCollapse, AccordionCollapseSummary } from '../../../Layout/Collapsible';

export const CompanyDocumentDataRow = (props: {
  companyDocument: CompanyDocument;
  companySuccessCallback: any;
  userCanAdminCompany: boolean;
  company: Company;
}) => {
  const { companyDocument, companySuccessCallback, userCanAdminCompany, company } = props;
  const { applicationVersions: avs } = useAppSelector((state) => state.serviceCategories);
  const [deleteDocumentOpen, setDeleteDocumentOpen] = useState(false);
  const handleDeleteCompanyDocumentOpen = () => setDeleteDocumentOpen(true);
  const handleDeleteCompanyDocumentClose = () => setDeleteDocumentOpen(false);
  
  const handleCompanyDocumentDeleteSubmit = () => {
    deleteCompanyDocument(companyDocument).then(() => {
      const updatedCompany: Company = {
        ...company,
        companyDocuments: company.companyDocuments?.filter(
          (companyDoc: CompanyDocument) => companyDoc.id != companyDocument.id,
        ),
      };
      companySuccessCallback(updatedCompany);
      handleDeleteCompanyDocumentClose();
    });
  };

  const documentSites = useMemo(() => {
    return company.locations.filter((loc) => companyDocument.sites.includes(loc?.id!));
  }, [companyDocument, company]);

  const documentServices = useMemo(() => {
    return company.services.filter((svc) => companyDocument.services.includes(svc.id));
  }, [companyDocument, company]);

  const documentApps = useMemo(() => {
    if (!avs.length || !companyDocument.applicationVersions || companyDocument.applicationVersions.length === 0) {
      return undefined;
    }

    let result: string[] = [];
    const companyApplications = company?.companyApplications!.filter(
      (ca) => ca.application.company === company.id && ca.application.versions && ca.application.versions.length > 0,
    );
    companyApplications.forEach((ca) => {
      (ca.application.versions as Version[])?.forEach((cav) => {
        if (companyDocument.applicationVersions.includes(cav.id!)) {
          result.push(`${ca.application.name} ${cav.id}`);
        }
      });
    });

    if (!result.length) {
      return undefined;
    }

    return [...new Set(result)];
  }, [avs, companyDocument.applicationVersions]);

  return (
    <DataRow onClick={() => {}} hover>
      <StandardDialog
        title='Delete Company Document'
        handleClose={handleDeleteCompanyDocumentClose}
        isOpen={deleteDocumentOpen}
      >
        <Typography>
          Are you sure you want to delete {companyDocument.name ? companyDocument.name : 'this document'}?
        </Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => handleDeleteCompanyDocumentClose()}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Delete />}
            onClick={() => handleCompanyDocumentDeleteSubmit()}
            disabled={!userCanAdminCompany}
          >
            Delete
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <DataCell xs={3}>
        <Typography sx={{ fontSize: '1.2em' }}>
          <a href={companyDocument.document} target='_blank' rel='noreferrer'>
            {companyDocument.name}
          </a>
        </Typography>
      </DataCell>
      <DataCell xs={2}>{ChipsWithOverflow(documentSites?.map((site) => site.name))}</DataCell>
      <DataCell xs={2}>{ChipsWithOverflow(documentServices?.map((service) => service.name))}</DataCell>
      <DataCell xs={2}>{ChipsWithOverflow(documentApps)}</DataCell>
      <DataCell xs={2}>{Moment(companyDocument.createdAt).utc().format('MM/DD/YYYY')}</DataCell>
      <DataCell xs={1}>
        <DataRowActions>
          <a href={companyDocument.document} target='_blank' rel='noreferrer'>
            <IconButton size='small' name='View Document'>
              <LinkOutlined />
            </IconButton>
          </a>
          {userCanAdminCompany && (
            <IconButton
              size='small'
              name='Delete Site'
              onClick={() => handleDeleteCompanyDocumentOpen()}
              disabled={!userCanAdminCompany}
            >
              <Delete />
            </IconButton>
          )}
        </DataRowActions>
      </DataCell>
    </DataRow>
  );
};

export default function CompanyDocumentsAccordion(props: {
  company: Company;
  companyCallback: any;
  userCanAdminCompany: boolean;
}) {
  const { company, userCanAdminCompany, companyCallback } = props;
  const { handleSubmit, register, reset } = useForm();
  const { profileSection } = useParams();
  // state
  const [expanded, setExpanded] = useState(false);
  const { results: assessments } = useAppSelector((state) => state.assessments);
  const [documentError, setDocumentError] = useState('');
  const [selectedCompanyDocument, setSelectedCompanyDocument] = useState(undefined);
  const [createCompanyDocumentOpen, setCreateCompanyDocumentOpen] = useState(false);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [selectedApplications, setSelectedApplications] = useState<number[]>([]);
  // handlers
  const handleCreateCompanyDocumentOpen = () => setCreateCompanyDocumentOpen(true);
  const handleCreateCompanyDocumentClose = () => {
    reset();
    setSelectedSites([]);
    setSelectedServices([]);
    setSelectedApplications([]);
    setCreateCompanyDocumentOpen(false);
  };
  const handleCompanyDocumentCreation = (companyResponse: Company) => {
    companyCallback(companyResponse);
    reset();
    handleCreateCompanyDocumentClose();
  };

  const locationOptions = (locations: Contact[]) => {
    if (!assessments) return locations;
    return locations.filter(
      (loc) =>
        !assessments.find((ass) =>
          (ass.surveys as AssessmentSurvey[])?.find((asssur) => (asssur.survey as Survey).site === loc.id),
        ),
    );
  };

  const applicationOptions = (companyApplications?: CompanyApplication[]) => {
    if (!companyApplications?.length) return [];
    companyApplications = companyApplications.filter(
      (companyApp) =>
        companyApp.application.company === company.id &&
        companyApp.application.versions &&
        companyApp.application.versions.length > 0,
    );
    let options: Array<Application | Version> = [];
    companyApplications.forEach((ca) => {
      options = options.concat([ca.application, ...(ca.application.versions as Version[])]);
    });
    return options;
  };

  // for accordion expanded
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'documents');
  }, [profileSection]);

  const handleCompanyDocumentUpdate = (companyDoc: CompanyDocument) => {
    const updateRequest: CompanyDocumentUpdateData = {
      name: companyDoc.name,
      company: companyDoc.company,
      sites: selectedSites,
      services: selectedServices,
      applicationVersions: selectedApplications,
    };
    return updateCompanyDocument(companyDoc.id, updateRequest);
  };

  const onSubmit = async (data: FieldValues) => {
    if (!company?.id) return;
    if (!selectedCompanyDocument) {
      setDocumentError('Please Select A File to Upload');
      return;
    }

    const newCompanyDoc: CompanyDocumentSubmitData = {
      name: data.name,
      document: selectedCompanyDocument,
      company: company.id,
    };

    try {
      let companyDoc = await createCompanyDocument(newCompanyDoc);
      companyDoc = await handleCompanyDocumentUpdate(companyDoc);
      const companyDocs: CompanyDocument[] = company.companyDocuments ? company.companyDocuments : [];
      const updatedCompany: Company = {
        ...company,
        companyDocuments: [...companyDocs, companyDoc],
      };
      setDocumentError('');
      handleCompanyDocumentCreation(updatedCompany);
    } catch (error: any) {
      if (error.response.data?.document) {
        setDocumentError(error.response.data.document[0]);
      }
    }
  };

  return (
    <AccordionCollapse
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionCollapseSummary
        name='documents-header'
        title='Documents'
        count={company.companyDocuments ? company.companyDocuments.length : 0}
        addItemTest={userCanAdminCompany}
        addItem={() => handleCreateCompanyDocumentOpen()}
        addItemLabel='Document'
      >
        <StandardDialog
          title='Upload Document'
          handleClose={handleCreateCompanyDocumentClose}
          isOpen={createCompanyDocumentOpen}
        >
          <Grid item xs={12} mt={2} component='form' method='post' onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin='normal'
                id='name'
                label='Description'
                autoFocus
                {...register('name')}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <FileUploaderComp
                multiple={false}
                callback={(file: any) => {
                  setDocumentError('');
                  setSelectedCompanyDocument(file[0].file);
                }}
              />
              {documentError && (
                <Alert severity='error' sx={{ mt: 1 }}>
                  {documentError}
                </Alert>
              )}
            </Grid>
            <Typography variant='caption'>
                Please be advised that documents uploaded to this section, including your Legacy TPN Assessment Reports, will not be watermarked upon user download--this includes Content Owners. If you require watermarking, please direct Content Owners to the TPN Box account.
            </Typography>
            <Grid item xs={12} mt={2}>
              <MultipleSelectChip
                options={locationOptions(company.locations)}
                optionsCallback={setSelectedSites}
                selectedOptions={selectedSites}
                inputLabel='Sites'
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <MultipleSelectChip
                options={company.services}
                optionsCallback={setSelectedServices}
                selectedOptions={selectedServices}
                inputLabel='Services'
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <MultipleSelectChip
                options={applicationOptions(company.companyApplications)}
                optionsCallback={setSelectedApplications}
                selectedOptions={selectedApplications}
                inputLabel='Applications'
                isApplication={true}
              />
            </Grid>
            <StandardDialogActions>
              <Button type='submit' variant='contained'>
                Upload
              </Button>
            </StandardDialogActions>
          </Grid>
        </StandardDialog>
      </AccordionCollapseSummary>
      <AccordionDetails>
        <DataRow header>
          <DataCell xs={3}>Document</DataCell>
          <DataCell xs={2}>Sites</DataCell>
          <DataCell xs={2}>Services</DataCell>
          <DataCell xs={2}>Applications</DataCell>
          <DataCell xs={2}>Upload Date</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {company?.companyDocuments?.map((companyDoc: CompanyDocument) => (
          <CompanyDocumentDataRow
            key={companyDoc.id}
            companyDocument={companyDoc}
            companySuccessCallback={companyCallback}
            userCanAdminCompany={userCanAdminCompany}
            company={company}
          />
        ))}
      </AccordionDetails>
    </AccordionCollapse>
  );
}